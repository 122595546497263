<template>
  <div class="wrap">
    <van-nav-bar :title="$t('newTabbar.goodsPage.invite')">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
    </van-nav-bar>
    <div class="invite_wrap">
      <img src="@/assets/icon/invite_banner.jpg" alt="" />
      <ul>
        <li>
          <p class="iconfont">&#xe60f;</p>
          <p>First</p>
          <p>level:0.0000%</p>
        </li>
        <li>
          <p class="iconfont">&#xe60f;</p>
          <p>Level</p>
          <p>2:0.0000%</p>
        </li>
        <li style="margin-right: 0">
          <p class="iconfont">&#xe60f;</p>
          <p>Level</p>
          <p>3:0.0000%</p>
        </li>
      </ul>
      <div class="code_link">
        <div class="text_wrap">
          <div class="title">{{ $t("newAdd.referralLink") }}</div>
          <div class="code_num">
            <div class="text">{{ shareUrl }}</div>

          </div>
        </div>
      </div>
      <div class="btn" v-clipboard:copy="shareUrl" v-clipboard:success="copySuccess" v-clipboard:error="copyError">
        {{ $t("newAdd.copy") }}
      </div>
    </div>
    <div class="else_wrap" v-show="menuVal === index + 3" v-for="(item, index) in menuArr" :key="index"
      v-html="item.content"></div>
  </div>
</div></template>
<script>
import { NavBar, icon, Toast } from "vant";
import { mapGetters } from "vuex";
export default {
  components: {
    [NavBar.name]: NavBar,
    [icon.name]: icon,
    Toast
  },
  data() {
    return {
      menuArr: [],
      menuVal: 0,
      loadingNum: 0,
    }
  },
  computed: {
    ...mapGetters(["banner", "shareUrl"]),
  },
  created() {
    Toast.loading({
      forbidClick: true,
      duration: 0,
    });
    this.getMenuArr();
  },
  methods: {
    copySuccess() {
      Toast(this.$t("newAdd.copySuccess"));
    },
    copyError() {
      Toast(this.$t("newAdd.copyError"));
    },
    getMenuArr() {
      this.loadingNum++;
      this.$api.getHomeConfig(
        (res) => {
          this.loadingNum--;
          this.menuArr = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    }
  },
  watch: {
    loadingNum: (newVal) => {
      if (!newVal) {
        setTimeout(() => {
          Toast.clear();
        }, 1000);
      }
    },
  },
}
</script>
<style lang="less" scoped>
@white: #fff;
@themeColor: var(--navBg);

.widthCalc(@name, @width) {
  @{name}: calc(~"@{width}" / 16)
}

.wrap {
  min-height: 100vh;
  background-color: #f4f8fb;

  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      background-color: @themeColor;
      .widthCalc(height, 50rem);
    }

    /deep/ .van-nav-bar__title {
      color: @white;
    }
  }

  .invite_wrap {
    width: 100%;

    img {
      width: 100%;
    }

    ul {
      display: flex;
      .widthCalc(padding, 8rem);
      .widthCalc(padding-left, 16rem);
      .widthCalc(padding-right, 16rem);

      >li {
        width: 33%;
        margin-right: 1px;
        background: rgba(220, 231, 252, 0.6);
        padding: 4px 0;
        border-radius: 2px;

        >p {
          border-radius: 2px;
          text-align: center;
          line-height: 1.2;
          font-size: calc(12rem / 16);
          color: #7c83a9;
        }

        >p:nth-child(1) {
          font-size: calc(20rem / 16);
        }
      }
    }

    >.invite_btn {
      margin-top: calc(5rem / 16);
      width: 100%;
      display: flex;
      justify-content: center;

      >button {
        // width: calc(62rem / 16);
        padding: calc(8rem / 16) calc(20rem / 16);
        background-color: #eaad5f;
        border-radius: 5px;
        text-align: center;
        // line-height: calc(25rem / 16);
        font-size: calc(16rem / 16);
        font-weight: 600;
        color: #fff;
        justify-content: flex-end;
        outline: none;
        border: none;
      }
    }

    >.code_link {
      // max-width: 100%;
      display: flex;
      justify-content: space-between;
      background: rgba(220, 231, 252, 0.6);
      align-items: center;
      border-radius: 4px;
      margin: calc(16rem / 16);
      margin-top: calc(8rem / 16);
      .widthCalc(padding,8rem);

      
      >.text_wrap {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;

        // overflow: auto;
        >.title {
          font-size: calc(12rem / 16);
          color: #9da7c0;
          margin-left: 4px;
          // white-space: nowrap;
          flex-shrink: 0;
          line-height: calc(25rem / 16);
        }

        >.code_num {
          text-indent: calc(5rem / 16);
          flex-grow: 1;
          flex-shrink: 1;
          // max-width: 60%;
          font-size: calc(14rem / 16);
          color: #cfa17b;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          position: relative;

          >.text {
            position: absolute;
            left: 0;
            // max-width: 68%;
            z-index: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box; //作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
            -webkit-line-clamp: 1; //显示的行
          }
        }
      }
    }
    .btn {
      background-color: @themeColor;
      border-radius: 5px;
      text-align: center;
      font-size: calc(14rem / 16);
      font-weight: 700;
      color: @white;
      justify-content: flex-end;
      .widthCalc(padding, 16rem);
      .widthCalc(margin, 16rem)
    }
  }
}
</style>